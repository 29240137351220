<template>
  <span>
    <b-row class="mb-2">
      <b-col>
        <app-timeline>
          <app-timeline-item
            v-for="(item, index) in dataSource"
            :item="item"
            :key="index"
            :title="item.ticket_no"
            :subtitle="item.remarks"
            :time="item.preview_date"
            :file="item.file"
            :reference="item.reference_type"
          />
        </app-timeline>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Remarks" label-for="v-remarks">
          <b-form-textarea
            rows="4"
            v-model="formData.remarks"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Upload File" label-for="v-file-upload">
          <b-form-file
            v-model="onFileUpload"
            :accept="acceptedFile"
            placeholder="Choose/Drop a file here..."
            drop-placeholder="Drop a file here..."
            id="v-sr-timeline-file-upload"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-right">
        <b-button type="button" variant="success" @click="onSubmit()">
          Submit
        </b-button>
      </b-col>
    </b-row>
  </span>
</template>

<script>
// @todo change from utils classes to global-function
import { unixToDisplayDateTime } from '@/utils/classes/dates';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

export default {
  name: 'JobOrderTimeline',
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    dataSource(items) {
      const displayToLocale = (date) => {
        return unixToDisplayDateTime(date);
      };

      for (const item of items) {
        item.preview_date = displayToLocale(item.created_at);
      }
    },
    onFileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.formData.file = file.name;
          this.formData.base64 = reader.result;
        };
      }
    },
  },
  data: () => ({
    onFileUpload: null,
    acceptedFile: '*',
    formData: {
      header_id: 0,
      remarks: null,
      reference_type: 'User',
      file: null,
      base64: null,
    },
  }),
  mounted() {
    //
  },
  methods: {
    onSubmit() {
      this.$emit('on-submit-timeline', this.formData);
      this.onFileUpload = null;
      this.formData = {
        header_id: 0,
        remarks: null,
        reference_type: 'User',
        file: null,
        base64: null,
      };
    },
  },
};
</script>

<style></style>
