<template>
  <span>
    <DxDataGrid
      key-expr="id"
      :data-source="dataSource"
      :focused-row-enabled="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      @init-new-row="handleEvent('init-new-row', $event)"
      @option-changed="handleEvent('option-changed', $event)"
      @row-removed="handleEvent('row-removed', $event)"
    >
      <DxEditing
        :allow-updating="!isDisabled"
        :allow-deleting="!isDisabled"
        :allow-adding="!isDisabled"
        :use-icons="!isDisabled"
        mode="row"
      />

      <DxColumn
        v-for="(item, index) in dataColumns"
        :key="index"
        :data-field="item.field"
        :caption="item.caption"
        :cell-template="item.template"
      />

      <DxColumn type="buttons">
        <DxButton icon="email" :onClick="onSendPDF" />
      </DxColumn>

      <DxPaging :page-size="10" />
      <DxPager
        :visible="true"
        :allowed-page-sizes="[10, 20, 50, 100]"
        display-mode="full"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />

      <DxColumnChooser :enabled="true" />
      <DxColumnFixing :enabled="true" />
      <DxSorting mode="multiple" />
      <DxFilterRow :visible="true" />
      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="false" />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel location="before" :visible="true" />

      <DxToolbar>
        <DxItem location="before" name="columnChooserButton" />
        <DxItem location="before" name="searchPanel" />
        <DxItem name="groupPanel" />
      </DxToolbar>

      <template #linkTemplate="{ data }">
        <a :href="`${data.displayValue}`" target="_blank">
          {{ data.data.link }}
        </a>
      </template>
    </DxDataGrid>
  </span>
</template>

<script>
import jobOrderHeadersApi from '@api/joborder_headers';
// @todo change from utils classes to global-function
import { unixToDisplayDateTime } from '@/utils/classes/dates';

export default {
  name: 'JobOrderPDFVersionsPage',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dataSource(items) {
      const displayToLocale = (date) => {
        return unixToDisplayDateTime(date);
      };

      for (const item of items) {
        item.url = `${process.env.VUE_APP_ROOT_STATIC_URL}/joborder/${item.link}`;
        item.generated_date = displayToLocale(item.generated_date);
      }
    },
  },
  data: () => ({
    dataColumns: [
      { caption: 'Version', field: 'version_number' },
      { caption: 'Generated Date', field: 'generated_date' },
      { caption: 'Generated By', field: 'generated_by' },
      { caption: 'Link', field: 'url', template: 'linkTemplate' },
    ],
  }),
  mounted() {
    //
  },
  methods: {
    onSendPDF(event) {
      const { data } = event.row;
      const formData = { id: this.$route.query.id, pdf: data.link };

      jobOrderHeadersApi
        .sendServiceReport(formData)
        .then(({ data }) => {
          console.log(data);
          this.toastConfig();
        })
        .catch(() => {
          this.toastConfig('', 'error');
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      if (action == 'row-removed') {
        //
      }
    },
    getData(data) {
      console.log(data);
    },
  },
};
</script>

<style></style>
